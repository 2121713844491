@keyframes moveSlideshow {
  0% {
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0%);
  }
}
.YnetComponent {
  min-width: 15vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.YnetComponent .title {
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 20vw;
  text-align: right;
  background-color: #005074;
  margin: 0;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}
.YnetComponent .title:after {
  content: " ";
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 10px;
  background-color: #fff;
}
.YnetComponent .title h2 {
  padding-right: 10px;
  font-weight: bold;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  margin: 0;
}
.YnetComponent .mask {
  height: 10px;
  width: 20vw;
  background-color: white;
}
.YnetComponent .news-container {
  height: 100rem;
  width: 20vw;
  overflow: hidden;
  padding: 0 5px;
  box-sizing: border-box;
}
.YnetComponent .news-container .slide {
  animation: moveSlideshow 120s linear infinite;
}
.YnetComponent .news-container .news {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
}
.YnetComponent .news-container .news h2 {
  margin: 0;
}
.YnetComponent .news-container .news .time {
  width: 20vw;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #9f1416;
}
.YnetComponent .news-container .news .text {
  width: 20vw;
  height: fit-content;
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  font-weight: normal;
}
 