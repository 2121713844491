 /* Container needed to position the overlay. Adjust the width as needed */
.qr-container {
  /*position: relative;*/
  cursor: pointer;
  /*width: 128px;*/
  /*height: 128px;*/
}

/* Make the image to responsive */
.qr-code-div {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: relative;
  top: -100%;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, 0.6);
}

/* When you mouse over the container, fade in the overlay icon*/
.qr-container:hover .overlay {
  opacity: 0.8;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.download-icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/*!* When you move the mouse over the icon, change color *!*/
/*.fa-user:hover {*/
/*  color: #eee;*/
/*} */
