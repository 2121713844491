
.container {
    font-size: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: none;
}

.item {
    width: 100%;
    margin: 1rem auto;
    position: center;
}