/* richTextEditor2.css */

/* General Editor Styling */
.rdw-editor-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fdfdfd;
    padding: 10px;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

/* Toolbar Styling */
.rdw-editor-toolbar {
    background-color: #f7f7f9;
    border: 1px solid #ddd;
    padding: 5px;
    border-bottom: 1px solid #ccc;
}

.rdw-editor-toolbar button {
    color: #444;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.rdw-editor-toolbar button:hover {
    background-color: #e6f7ff;
}

/* Editor Content Area */
.rdw-editor-main {
    min-height: 200px;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    overflow-y: auto;
}

/* Placeholder Styling */
.public-DraftEditorPlaceholder-root {
    color: #bbb;
    font-style: italic;
}

/* Text Styling */
.DraftEditor-root {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
}

/* Selected Text Highlight */
.rdw-option-active {
    background-color: #91d5ff;
    border-radius: 4px;
}

/* Scrollbar Customization */
.rdw-editor-main::-webkit-scrollbar {
    width: 8px;
}

.rdw-editor-main::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.rdw-editor-main::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.custom-editor-wrapper {
    background-color: #ffffff;
    border: none;
}

.custom-editor{
    border: 1px solid #000;
    border-radius: 10px;
    background-color: #fdfdfd;
    padding: 10px;
    margin-bottom: 20px;
    font-family: David, sans-serif;
    font-size: 14px;
}

.custom-toolbar {
    background-color: #f7f7f9;
    border: 1px solid #000;
    padding: 5px;
    border-radius: 10px;
}

.display-only-mode {
    display: block; /* Makes the div take the full width of its parent */
    width: 100%; /* Ensures the width matches the parent container */
    background-color: #fafafa; /* Example background for visual clarity */
    border: 1px solid #000;
    border-radius: 10px;
    padding: 5px; /* Adds space around the text */
    font-family: David, sans-serif; /* Choose a readable font */
    font-size: 14px; /* Adjust font size as needed */
    line-height: 1.6; /* Improves text readability */
    white-space: normal; /* Allows text to wrap and fit vertically */
    overflow-wrap: break-word; /* Ensures long words wrap properly */
    text-align: right; /* Aligns text to the right */
    direction: rtl; /* Sets text direction to right-to-left */
}